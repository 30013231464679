@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --rotateX: 0,
  --rotateY: 0
}

@font-face {
  font-family: 'GothamBook';
  src: url('./fonts/Gotham_Book/Gotham-Book.otf');
}

@layer base {
  body {
    @apply m-0 p-0 box-border bg-main-dark font-gotham;
  }

  h1 {
    @apply text-main-light;
  }

  li {
    @apply list-none;
  }

  ::-webkit-scrollbar-track {
    @apply bg-transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    @apply bg-extra-light rounded-md;
  }
  
  ::-webkit-scrollbar {
    @apply w-1 h-1;
  }
}

@layer components {
  .partial-bg {
    clip-path: polygon(6% 68%, 19% 74%, 20% 84%, 34% 84%, 45% 93%, 100% 100%, 0 100%, 0 0);
  }

  .outline-logo {
    @apply absolute w-[7.25rem] h-[7.25rem] rounded-full flex items-center justify-center;
  }

  .page-link {
    @apply w-4 h-4 rounded-3xl border-2 border-main-light hover:scale-90 hover:bg-extra-light hover:border-sub-light transition-all duration-200 ease-in-out;
  }

  .external-link {
    @apply text-main-light hover:scale-[135%] hover:text-extra-light transition-transform duration-200 ease-in-out;
  }

  .text-animate {
    font-kerning: none;
  }

  .md-center-absolute {
    @apply left-1/2 transform -translate-x-1/2 bottom-2;
  }

  .lg-left-center-absolute {
    @apply lg:ml-4 lg:bottom-1/2 lg:left-0 lg:translate-y-1/2 lg:translate-x-0 flex flex-col gap-4;
  }

  .lg-right-center-absolute {
    @apply lg:mr-4 lg:bottom-1/2 lg:right-0 lg:translate-y-1/2 lg:translate-x-0 flex flex-col;
  }

  .text-shadow {
    text-shadow: 0 0 0.09em currentColor;
  }

  .project-link {
    @apply hover:bg-main-dark hover:border-extra-light py-2 px-4 rounded-md bg-extra-light border-2 border-main-dark text-main-dark transition-all duration-200 ease-in-out;
  }

  .project-page {
    @apply border-2 border-sub-light p-2 rounded-md bg-main-dark grid place-items-center shadow-project-shadow hover:border-extra-light hover:shadow-project-hover-shadow transition-all duration-200 ease-in-out; 
  }
}

@layer utilities {

  .current-page {
    @apply scale-90 bg-extra-light border-sub-light;
  }

  .curr-project {
    @apply bg-main-dark border-extra-light;
  }

  .rotate-elem {
    position: relative;
    transition: all .15s linear;
    transform-style: preserve-3d;
    transform:
      perspective(5000px)
      rotateX(var(--rotateX))
      rotateY(var(--rotateY));
  }

  .rotate-elem:hover {
    transform:
      perspective(5000px)
      rotateX(0)
      rotateY(0);
  }

  .rotate-elem::before,
  .rotate-elem::after {
    content: "";
    position: absolute;
    border-radius: inherit;
  }

  .rotate-elem::before {
    inset: 0.75rem;
    border-radius: inherit;
    background: #f5cb5c;
    z-index: -1;
    transform: translateZ(-50px);
    filter: blur(15px);
    opacity: 0.25;
  }

  .rotate-elem::after {
    z-index: -2;
    inset: -.25rem;
    background: linear-gradient(to right bottom, #284e5d, #2f5666, #365f6e, #3d6777, #447080, #4a7687, #4f7d8d, #558394, #5b889a, #608ea0, #6693a7, #6c99ad);
    opacity: .15;
    transform: translateZ(-50px);
    filter: blur(4px);
  }

  .button {
    --offset: 10px;
    --border-size: 2px;
    display: block;
    position: relative;
    box-shadow: inset 0 0 0 var(--border-size) currentcolor;
    transition: background .8s ease;
  }
  
  button .horizontal,
  button .vertical {
    pointer-events: none;
    position: absolute;
    top: var(--horizontal-offset, 0);
    right: var(--vertical-offset, 0);
    bottom: var(--horizontal-offset, 0);
    left: var(--vertical-offset, 0);
    transition: transform .8s ease;
    will-change: transform;
  }
  
  button .horizontal::before,
  button .vertical::before {
    content: '';
    position: absolute;
    border: inherit;
  }
  
  button .horizontal {
    --vertical-offset: calc(var(--offset) * -1);
    border-top: var(--border-size) solid currentcolor;
    border-bottom: var(--border-size) solid currentcolor;
  }
  
  button .horizontal::before {
    top: calc(var(--vertical-offset) - var(--border-size));
    bottom: calc(var(--vertical-offset) - var(--border-size));
    left: calc(var(--vertical-offset) * -1);
    right: calc(var(--vertical-offset) * -1);
  }
  
  button:hover .horizontal {
    transform: scaleX(0);
  }
  
  button .vertical {
    --horizontal-offset: calc(var(--offset) * -1);
    border-left: var(--border-size) solid currentcolor;
    border-right: var(--border-size) solid currentcolor;
  }
  
  button .vertical::before {
    top: calc(var(--horizontal-offset) * -1);
    bottom: calc(var(--horizontal-offset) * -1);
    left: calc(var(--horizontal-offset) - var(--border-size));
    right: calc(var(--horizontal-offset) - var(--border-size));
  }
  
  button:hover .vertical {
    transform: scaleY(0);
  }
}